<template>
  <div
    class="applications-check-block__check-element"
    :class="{ 'applications-check-block__check-element--edit': for_table }"
  >
    <div class="time-fields-block">
      <span
        class="applications-check-block__check-element--text"
        :class="{ invalid_field: this.invalid.recording_start_time }"
      >
        {{ $t("applications.recording_start") }}</span
      >
      <custom-time-field
        ref="recording_start"
        class="time-fields-block__field"
        :disabled="new_application.recording_mode"
        v-model="new_application.recording_start_time"
        :value="new_application.recording_start_time"
        :placeholder="$t('accounts.text_field_hint')"
        @change="refresh"
      ></custom-time-field>
    </div>

    <div class="time-fields-block">
      <span
        class="applications-check-block__check-element--text"
        :class="{ invalid_field: this.invalid.recording_end_time }"
      >
        {{ $t("applications.recording_end") }}</span
      >
      <custom-time-field
        ref="recording_end"
        class="time-fields-block__field"
        :disabled="new_application.recording_mode"
        v-model="new_application.recording_end_time"
        :value="new_application.recording_end_time"
        :placeholder="$t('accounts.text_field_hint')"
        @change="refresh"
      ></custom-time-field>
    </div>
  </div>
</template>

<script>
import CustomTimeField from "@/components/recurringComponents/customTimeField";
import rules from "@/rules";
export default {
  name: "applicationsTimeFields",
  components: {
    CustomTimeField,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    for_table: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: rules,
      invalid: {
        recording_end_time: false,
        recording_start_time: false,
      },
      error: "",
    };
  },
  computed: {
    new_application: {
      get() {
        if (this.edit) {
          return this.$store.getters.EDIT_APPLICATION;
        }
        return this.$store.getters.NEW_APPLICATION;
      },
      set(value) {
        if (this.edit) {
          this.$store.dispatch("update_edit_application", value);
        } else {
          this.$store.dispatch("update_new_application", value);
        }
      },
    },
  },
  methods: {
    validate() {
      this.invalid.recording_start_time = false;
      this.invalid.recording_end_time = false;
      if (this.new_application.recording_mode) {
        return {
          valid: true,
          error: "",
        };
      } else {
        let valid = this.check_values();
        if (!valid.valid) {
          this.invalid.recording_end_time = true;
          this.invalid.recording_start_time = true;
        }
        return {
          valid: valid.valid,
          error: typeof valid.error === "string" ? valid.error : "",
        };
      }
    },
    check_values() {
      let has_value =
        rules.require(this.new_application.recording_end_time) &&
        rules.require(this.new_application.recording_start_time);
      let valid_range = rules.valid_range(
        this.new_application.recording_start_time,
        this.new_application.recording_end_time
      );
      let error_text = "";
      if (typeof valid_range === "string") {
        error_text = valid_range;
      }
      if (typeof has_value === "string") {
        error_text = has_value;
      }
      return {
        valid:
          typeof has_value === "boolean" && typeof valid_range === "boolean",
        error: error_text,
      };
    },
    refresh() {
      this.$emit("change", { value: this.new_application });
    },
  },
};
</script>

<style scoped>
.time-fields-block {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-right: 10px;
}

.applications-check-block__check-element {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 45px;
  margin-right: 140px;
}

.applications-check-block__check-element--edit {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 45px;
  margin-right: 170px;
}

.applications-check-block__check-element--text {
  margin-right: 10px;
}
</style>
