<template>
  <div class="select-filter select-filter_appearance">
    <div class="select-filter__wrapper" @click="show = !show">
      <div class="select-filter__stuff select-filter__stuff_appearance">
        <img
          class="select-filter__icon select-filter__icon_appearance"
          src="@/assets/edit-pencil.svg"
          alt=""
        />
      </div>
      <span class="select-filter__title select-filter__title_appearance">{{
        title
      }}</span>
    </div>
    <div
      class="select-filter__items-field select-filter__items-field_appearance"
      v-show="show"
    >
      <div
        @click="select_all"
        class="select-filter__item select-filter__item_appearance select_all"
      >
        <div class="select-filter__check-icon">
          <div
            :class="{ 'select-filter_selected-item': all_filters_selected() }"
          ></div>
        </div>
        <span
          class="select-filter__item-text select-filter__item-text_appearance"
          >{{ $t("monitoring.btn.select_all") }}</span
        >
      </div>
      <div
        v-for="item in items_list"
        :key="item.name"
        @click="select(item)"
        class="select-filter__item select-filter__item_appearance"
      >
        <div class="select-filter__check-icon">
          <div :class="{ 'select-filter_selected-item': selected(item) }"></div>
        </div>
        <span
          class="select-filter__item-text select-filter__item-text_appearance"
          >{{ item.title }}</span
        >
      </div>
      <div class="select-filter__button_appearance-wrapper">
        <custom-btn
          class="select-filter__button_appearance button button_theme_blue"
          @click="refund"
          >{{ $t("monitoring.btn.apply") }}</custom-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import CustomBtn from "@/components/recurringComponents/customBtn";
export default {
  name: "appearanceFilter",
  components: { CustomBtn },
  props: {
    title: String,
    items: Array,
    name: String,
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    const onClickOutside = (e) => {
      if (!this.$el.contains(e.target)) {
        this.show = false;
      }
    };
    document.addEventListener("click", onClickOutside);
    this.$on("hook:beforeDestroy", () =>
      document.removeEventListener("click", onClickOutside)
    );
  },
  mounted() {
    this.refund();
  },
  watch: {},
  computed: {
    items_list() {
      return this.items;
    },
    counter() {
      return this.$store.getters.SELECTED_FILTERS[this.name].length;
    },
  },
  methods: {
    selected(item) {
      return this.$store.getters.SELECTED_FILTERS[this.name].includes(
        item.name
      );
    },
    select(item) {
      this.$store.dispatch("select_filter", {
        name: this.name,
        value: item.name,
      });
    },
    refund() {
      this.show = false;

      let temp = [];
      this.items_list.forEach((item) => {
        if (item.selected) temp.push(item.name);
      });

      this.$emit("change", { name: this.name, value: temp });
      this.$store.dispatch("apply_appearance_filters");
    },
    select_all() {
      let data = {
        name: this.name,
        filters: this.all_filters_selected()
          ? []
          : this.items_list.map((item) => {
              return item.name;
            }),
      };
      this.$store.dispatch("select_all_appearance_filters", data);
    },
    all_filters_selected() {
      if (
        this.$store.getters.SELECTED_FILTERS[this.name].length ===
        this.items_list.length
      ) {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.select_all {
  width: 100%;
}
</style>
