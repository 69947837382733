<template>
  <div
    class="text-fields-block__row text-fields-block__row_account"
    :class="{ 'text-fields-block__row_account-change': for_table }"
  >
    <div
      class="text-fields-block__element text-fields-block__element_accounts"
      :class="{ 'text-fields-block__element_application-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.employee_id }">{{
        $t("applications.username")
      }}</span>
      <autocomplete
        v-on:selected="update_select"
        :value="new_application.employee_id"
        :rules="[rules.require]"
        id="user_select"
        ref="employee_id"
        :options="team"
        :clear="clear"
        :disabled="edit"
        v-on:reset="reset_clear"
        :placeholder="$t('team.text_field_hint')"
      ></autocomplete>
    </div>
    <div class="text-fields-block__element text-fields-block__element_accounts">
      <span :class="{ invalid_field: this.invalid.password }">{{
        $t("applications.password")
      }}</span>
      <custom-text-field
        ref="password"
        :rules="[rules.require, rules.password]"
        :disabled="edit"
        class="text-fields-block__field"
        v-model="new_application.password"
        :placeholder="$t('team.text_field_hint')"
        @change="refresh"
        ><simple-button
          v-if="!for_table"
          class="text-fields-block__generate-btn"
          :title="$t('applications.generate')"
          @click="generate"
        ></simple-button
      ></custom-text-field>
    </div>
    <div
      class="text-fields-block__element text-fields-block__element_accounts"
      :class="{ 'text-fields-block__element_application-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.mail }">{{
        $t("applications.email")
      }}</span>
      <custom-text-field
        ref="mail"
        :disabled="edit"
        :rules="mail_rule"
        :placeholder="$t('team.text_field_hint')"
        class="text-fields-block__field"
        v-model="new_application.email"
        @change="refresh"
      ></custom-text-field>
    </div>
    <div
      class="text-fields-block__element text-fields-block__element_accounts"
      :class="{ 'text-fields-block__element_application-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.city_id }">{{
        $t("applications.city")
      }}</span>
      <autocomplete
        v-on:selected="update_city_select"
        :value="new_application.city_id"
        :rules="[rules.require]"
        id="city_select"
        ref="city_id"
        :options="cities"
        :clear="clear"
        :disabled="!new_application.employee_id"
        v-on:reset="reset_clear"
        :placeholder="$t('team.text_field_hint')"
      ></autocomplete>
    </div>
    <div
      class="text-fields-block__element text-fields-block__element_accounts"
      :class="{ 'text-fields-block__element_application-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.pos_id }">{{
        $t("applications.pos")
      }}</span>
      <autocomplete
        v-on:selected="update_pos_select"
        :value="new_application.pos_id"
        :rules="[rules.require]"
        id="pos_select"
        ref="pos_id"
        :options="pos"
        :clear="clear"
        :disabled="!new_application.city_id"
        v-on:reset="reset_clear"
        :placeholder="$t('applications.pos')"
      ></autocomplete>
    </div>
    <div
      class="text-fields-block__element text-fields-block__element_accounts"
      :class="{ 'text-fields-block__element_application-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.comment }">{{
        $t("applications.comment")
      }}</span>
      <custom-text-field
        ref="comment"
        :max="255"
        class="text-fields-block__field"
        v-model="new_application.comment"
        @change="refresh"
      ></custom-text-field>
    </div>
    <div
      v-if="!for_table"
      class="text-fields-block__element text-fields-block__element_accounts"
    ></div>
  </div>
</template>

<script>
import CustomTextField from "@/components/recurringComponents/customTextField";
import SimpleButton from "@/components/recurringComponents/simpleButton";
import rules from "@/rules";
import Autocomplete from "@/components/recurringComponents/autocomplete";

export default {
  name: "applicationsTextFields",
  props: {
    for_table: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
  },
  components: { SimpleButton, CustomTextField, Autocomplete },
  data() {
    return {
      rules: rules,
      clear: false,
      pos_raw: [],
      invalid: {
        comment: false,
        employee_id: false,
        mail: false,
        city_id: false,
        pos_id: false,
        recording_start: false,
        recording_end: false,
        password: false,
      },
    };
  },
  created() {
    this.$store.dispatch("load_team");
    if (this.edit) {
      this.$store.dispatch("load_cities");
    }
    if (this.new_application.city_id) {
      this.$store.dispatch("load_pos", this.new_application.city_id);
    }
  },
  computed: {
    team() {
      return this.$store.getters.TEAM.map((employee) => {
        return {
          value: employee.id,
          uuid: employee.id,
          text: `${employee.name} ${employee.surname}`,
        };
      });
    },
    cities() {
      return this.$store.getters.CITIES.map((city) => {
        return {
          value: city.id,
          uuid: city.id,
          pos: city.pos,
          text: `${city.name}`,
        };
      });
    },
    pos() {
      if (this.edit) {
        return this.$store.getters.POS.map((pos) => {
          return {
            value: pos.id,
            uuid: pos.id,
            address: pos.address,
            text: `${pos.name}`,
          };
        });
      }
      return this.pos_raw.map((pos) => {
        return {
          value: pos.id,
          uuid: pos.id,
          address: pos.address,
          text: `${pos.name}`,
        };
      });
    },
    new_application: {
      get() {
        if (this.edit) {
          return this.$store.getters.EDIT_APPLICATION;
        }
        return this.$store.getters.NEW_APPLICATION;
      },
      set(value) {
        if (this.edit) {
          this.$store.dispatch("update_edit_application", value);
        } else {
          this.$store.dispatch("update_new_application", value);
        }
      },
    },
    mail_rule() {
      let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return [
        (v) => !!v || "required",
        (v) => reg.test(v) || "no_mail",
        (v) => this.email_exist(v) || "mail_exist",
      ];
    },
  },
  methods: {
    email_exist(value) {
      if (this.for_table && this.new_account.mail === value) {
        return true;
      }
      let dubl = this.$store.getters.ACCOUNTS.find((account) => {
        return account.login === value;
      });
      if (dubl !== undefined) {
        return false;
      }
      return true;
    },
    refresh() {
      this.$emit("change", { value: this.new_application });
    },
    generate() {
      let symb = `!@#$%^&*()_+~\`|}{[]:;?><,./-=abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789`;
      symb = Array.from(symb);
      let pass = "";
      for (let i = 0; i <= Math.round(Math.random() * 4) + 10; i++) {
        let s = symb[Math.round(Math.random() * (symb.length - 1))];
        pass = pass + s;
      }
      if (rules.password(pass) === true) {
        this.new_application.password = pass;
        this.refresh();
      } else this.generate();
    },
    validate() {
      let error = false;
      let error_type = "";
      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        this._data["invalid"][key] = !valid.valid;
        if (!valid.valid) {
          error_type = valid.error;
          error = true;
        }
      }
      return { valid: !error, error: error_type };
    },
    reset_clear() {
      this.clear = false;
    },
    update_select(value) {
      this.new_application.employee_id = value;
      this.new_application.city_id = null;
      if (value) {
        this.$store.dispatch("load_cities_employee_id", {
          pos: false,
          id: value,
        });
      }
      this.error_text = "";
    },
    update_pos_select(value) {
      this.new_application.pos_id = value;
      this.error_text = "";
    },
    update_city_select(value) {
      this.clear_pos_fields();
      this.new_application.city_id = value;
      this.error_text = "";
      if (value) {
        if (this.edit) {
          this.$store.dispatch("load_pos", value);
        } else {
          this.pos_raw = this.cities.find((x) => x.value === value)?.pos;
        }
      }
    },
    clear_pos_fields() {
      this.new_application.pos_id = "";
      this.new_application.comment = "";
    },
  },
};
</script>

<style scoped></style>
