<template>
  <div class="accounts-check-block">
    <check-block
      class="accounts-check-block__check-element"
      v-model="new_application.recording_mode"
      :title="$t('applications.recording_mode')"
      :on_text="$t('applications.on_button_press')"
      :off_text="$t('applications.on_timer')"
      :small="true"
    ></check-block>
    <applications-time-fields
      ref="time_fields"
      :for_table="for_table"
      :edit="edit"
    ></applications-time-fields>
    <check-block
      class="accounts-check-block__check-element"
      v-model="new_application.motion_detection"
      :title="$t('applications.motion_detection')"
      :on_text="$t('applications.on')"
      :off_text="$t('applications.off')"
      :small="true"
    ></check-block>
  </div>
</template>

<script>
import CheckBlock from "@/components/recurringComponents/checkBlock";
import ApplicationsTimeFields from "@/components/components/applicationsTimeFields";

export default {
  name: "applicationsCheckBlock",
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    for_table: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ApplicationsTimeFields,
    CheckBlock,
  },
  data() {
    return {};
  },
  computed: {
    new_application: {
      get() {
        if (this.edit) {
          return this.$store.getters.EDIT_APPLICATION;
        }
        return this.$store.getters.NEW_APPLICATION;
      },
      set(value) {
        if (this.edit) {
          this.$store.dispatch("update_edit_account", value);
        } else {
          this.$store.dispatch("update_new_account", value);
        }
      },
    },
  },
  methods: {
    validate() {
      return this.$refs.time_fields.validate();
    },
    // refresh() {
    //   this.$emit("input", this.new_account);
    //   this.$emit("change");
    // },
  },
};
</script>

<style scoped></style>
