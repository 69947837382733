<template>
  <div class="change-block">
    <applications-text-fields
      ref="fields"
      class=""
      :for_table="true"
      :edit="true"
    ></applications-text-fields>
    <applications-check-block
      ref="check_block"
      :edit="true"
      :for_table="true"
      class="accounts__check-block"
    ></applications-check-block>

    <div
      v-if="info.loader"
      class="change-block__btn-block change-block__btn-block_accounts"
    >
      <div class="change-block__info-block">
        <loader></loader>
        <span
          class="text-fields-block__info-text text_color_green"
          :class="{ text_color_red: info.error }"
          >{{ info_text }}</span
        >
      </div>
    </div>
    <div
      v-else
      class="change-block__btn-block change-block__btn-block_accounts"
    >
      <div>
        <custom-btn
          class="button button_theme_blue"
          :disabled="!this.value.pos_id"
          @click="edit_application"
          >{{ $t("accounts.change_block.save") }}</custom-btn
        >
        <simple-button
          :title="$t('accounts.change_block.cancel')"
          @click="$emit('cancel')"
        ></simple-button>
        <span
          class="text_color_green"
          :class="{ text_color_red: info.error }"
          >{{ info_text }}</span
        >
      </div>
      <custom-btn
        class="change-block__delete-button button button_theme_delete"
        @click="$emit('delete')"
      >
        <span>{{ $t("accounts.change_block.delete") }}</span>
        <div class="change-block__delete-icon"></div>
      </custom-btn>
    </div>
  </div>
</template>

<script>
import CustomBtn from "@/components/recurringComponents/customBtn";
import SimpleButton from "@/components/recurringComponents/simpleButton";
import Loader from "@/components/recurringComponents/loader";
import ApplicationsCheckBlock from "@/components/components/applicationsCheckBlock";
import ApplicationsTextFields from "@/components/components/applicationsTextFields";

export default {
  name: "applicationsChangeBlock",
  props: ["value"],
  components: {
    Loader,
    ApplicationsCheckBlock,
    ApplicationsTextFields,
    SimpleButton,
    CustomBtn,
  },
  created() {
    this.$store.dispatch("update_edit_application", this.value);
  },
  data() {
    return {
      info: {
        error: false,
        type: "",
        loader: false,
      },
    };
  },
  computed: {
    info_text() {
      switch (this.info.type) {
        case "required":
          return this.$i18n.t("errors.required");
        case "invalid_range":
          return this.$i18n.t("errors.invalid_range");
        case "no_format":
          return this.$i18n.t("errors.format");
        case "no_password":
          return this.$i18n.t("errors.value");
        case "progress":
          return this.$i18n.t("applications.loader.progress_edit");
        case "failed":
          return this.$i18n.t("applications.loader.failed_edit");
        case "success":
          return this.$i18n.t("applications.loader.success_edit");
        default:
          return "";
      }
    },
  },
  methods: {
    validate() {
      this.info.error = false;
      this.info.type = "";
      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        if (!valid.valid) {
          this.info.error = true;
          this.info.type = valid.error;
        }
      }
      return !this.info.error;
    },
    edit_application() {
      if (this.validate()) {
        this.info.loader = true;
        this.info.type = "progress";
        const application_update_values = {
          id: this.value.id,
          recording_start_time: this.value.recording_start_time,
          recording_end_time: this.value.recording_end_time,
          employee_id: this.value.employee_id.toString(),
          recording_mode:
            this.value.recording_mode === true ? "button" : "scheduled",
          pos_id: this.value.pos_id,
          comment: this.value.comment,
          password: this.value.password,
          email: this.value.email,
          motion_detection: this.value.motion_detection,
        };
        this.$store
          .dispatch("update_application", application_update_values)
          .then(() => {
            this.info.type = "success";
          })
          .catch(() => {
            this.info.type = "failed";
            this.info.error = true;
          })
          .finally(() => {
            this.info.loader = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
